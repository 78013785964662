<script setup>

import MainRegion from "../Layouts/MainRegion.vue";
import {computed, onMounted, onUnmounted} from "vue";
import {Link, usePage} from "@inertiajs/vue3";
import OrderSummary from "../Components/Payment/OrderSummary.vue";
import {CartStore} from "@/Store/Cart";
import {PAYMENT_METHOD_INVOICE, PAYMENT_METHOD_PAYREXX} from "@/constants";
import {useForm} from "laravel-precognition-vue-inertia";
import Button from "@/Components/Form/Button.vue";

const settings = computed(() => usePage().props.settings);
const hasPaymentError = computed(() => usePage().props.has_payment_error);
const orderId = computed(() => usePage().props.order_id);

const cartStore = CartStore();
const invoicePaymentAllowed = computed(() => usePage().props.invoice_payment_allowed);

if (!invoicePaymentAllowed.value ||
    (cartStore.payment_method === PAYMENT_METHOD_INVOICE && !invoicePaymentAllowed.value)) {
    cartStore.payment_method = PAYMENT_METHOD_PAYREXX;
} else {
    if (!cartStore.payment_method) {
        cartStore.payment_method = PAYMENT_METHOD_INVOICE;
    }
}

const onChangePaymentMethod = (paymentMethod) => {
    cartStore.payment_method = paymentMethod;
    form.payment_method = paymentMethod;
};

onMounted(() => {
    if (typeof window !== 'undefined') {
        document.body.classList.add('order-page');
    }
});

onUnmounted(() => {
    if (typeof window !== 'undefined') {
        document.body.classList.remove('order-page');
    }
});

let formRoute = null;

if (typeof window !== 'undefined') {
    formRoute = route('checkout.payment.store');
}

const form = useForm('post', formRoute, {
    billing_address: {
        company: cartStore.billingAddress.company,
        first_name: cartStore.billingAddress.first_name,
        last_name: cartStore.billingAddress.last_name,
        street: cartStore.billingAddress.street,
        house_number: cartStore.billingAddress.house_number,
        zip_code: cartStore.billingAddress.zip_code,
        city: cartStore.billingAddress.city,
        phone: cartStore.billingAddress.phone,
    },
    email: cartStore.billingAddress.email,
    phone: cartStore.billingAddress.phone,
    items: cartStore.getItemsInCart,
    payment_method: cartStore.payment_method,
    discount_code: cartStore.discount_code,
    order_id: null,
    cart_id: cartStore.cart_id,
});

const submit = () => {
    form.items = cartStore.getItemsInCart;
    form.discount_code = cartStore.discount_code;

    if (orderId.value) {
        form.order_id = orderId.value;
    }

    form.submit({
        preserveScroll: true,
        onSuccess: (data) => {
            cartStore.clearCart();
            cartStore.syncCartWithApi();
        },
        onError: () => {
            cartStore.syncCartWithApi();
        }
    });
};

if (orderId.value) {
    submit();
}

</script>

<template>
    <MainRegion :simple-footer="true">
        <div class="container">
            <h1>{{ $t('Dein Warenkorb') }}</h1>
            <form id="payment-form" autocomplete="off" @submit.prevent="submit">
                <input autocomplete="false" name="hidden" style="display:none;" type="text">
                <input name="invoice_id" style="display:none;" type="text" value="invoice_id">
                <input name="temp_obj_id" style="display:none;" type="text" value="temp_obj_id">
                <input name="payment_status" style="display:none;" type="text" value="payment_status">
                <div class="steps">
                    <header class="step-panel completed">
                        <div class="step-panel-holder">
                            <h2>{{ $t('Bestellübersicht') }}</h2>
                            <Link :href="route('checkout')" class="link">{{ $t('Ändern') }}</Link>
                        </div>
                    </header><!-- / step-panel -->
                    <header class="step-panel active">
                        <div class="step-panel-holder">
                            <h2>{{ $t('Bezahlung') }}</h2>
                        </div>
                    </header><!-- / step-panel -->
                    <div class="step-entity">
                        <div class="payment-info">
                            <div class="row">
                                <div class="col-md-8">
                                    <div class="well delivery-block order-part">
                                        <header>
                                            <h2>{{ $t('Zahlungsart') }}</h2>
                                        </header>

                                        <div class="row">
                                            <div v-if="invoicePaymentAllowed" class="col-md-12">
                                                <div class="form-group">
                                                    <label class="radio-container" for="pay_on_delivery">
                                                        <input id="pay_on_delivery"
                                                               :checked="cartStore.payment_method === PAYMENT_METHOD_INVOICE"
                                                               data-toggle="radio"
                                                               name="payOnDelivery"
                                                               type="radio"
                                                               value="1"
                                                               @change="onChangePaymentMethod(PAYMENT_METHOD_INVOICE)">
                                                        <span class="checkmark"></span>
                                                        {{ $t('Rechnung') }}
                                                    </label>
                                                </div>
                                            </div>

                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label class="radio-container" for="pay_by_card">
                                                        <input id="pay_by_card"
                                                               :checked="cartStore.payment_method === PAYMENT_METHOD_PAYREXX"
                                                               class="custom-radio"
                                                               data-toggle="radio"
                                                               name="payOnDelivery"
                                                               type="radio"
                                                               value="0"
                                                               @change="onChangePaymentMethod(PAYMENT_METHOD_PAYREXX)">
                                                        <span class="checkmark"></span>
                                                        {{ $t('mit TWINT, Kreditkarte, Postfinance Card oder Postfinance E-Finance bezahlen') }}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                    </div><!-- / card-info -->
                                </div><!-- / col -->
                                <div class="col-md-4">
                                    <div id="orderSummary">
                                        <OrderSummary/>
                                    </div>
                                    <Button :disabled="form.processing"
                                            :loading="form.processing"
                                            class="btn-danger"
                                            type="submit">
                                        <a :href="route('agb')"
                                           class="privacy-link"
                                           target="_blank">{{
                                                $t('AGB')
                                            }}
                                        </a>&nbsp;
                                        {{ $t('Akzeptieren und jetzt kaufen') }}
                                    </button>

                                    <div v-if="hasPaymentError" id="errorMsgOrder" class="checkout-section">
                                        <div class="row">
                                            <div class="col-md-12">
                                                {{ $t('Oje, etwas ist schief gelaufen.') }}
                                                <br>
                                                <span>
                                                    {{
                                                        $t('Bitte versuchen Sie es noch einmal. Falls es nicht klappt, rufen Sie uns an (079 711 24 37) oder schreiben Sie uns eine E-Mail')
                                                    }}
                                                    (<a
                                                    :href="'mailto:'+settings['contact_email']">{{
                                                        settings['contact_email']
                                                    }}</a>)
                                                    {{ $t('Wir können dann Ihre Bestellung auslösen.') }}
                                                </span>
                                                <br> {{ $t('Freundliche Grüsse') }}
                                                <br> {{ $t('Ihr Schweizer Päckli Team') }}
                                            </div>
                                        </div>
                                    </div>
                                </div><!-- / col -->
                            </div><!-- / row -->
                        </div><!-- / payment-info -->
                    </div><!-- / step-entity -->
                </div><!--/ steps -->
            </form>
        </div><!-- / container -->
    </MainRegion>
</template>

<style scoped>
.privacy-link {
    color: #fff;
    text-decoration: underline;
}
</style>


<!--
<script>
var $form = $('#payment-form');

{% if invoiceMethodAllowed %}
var fields_required = false;
{% else %}
var fields_required = true;
{% endif %}

function onChangePaymentMethod(value) {
  fields_required = value;
}

$(document).ready(function () {
  jQuery("#payrexx-modal-window").payrexxModal({
    hidden: function () {
      $('#buy-btn').prop('disabled', false);
    }
  });
  $form = $('#payment-form');
  $form.validate({
    errorElement: 'em',
    errorPlacement: function (error, element) {
      return false;

      var $labelHolder = element.closest('.form-group').find('.lbl-holder');

      if ($labelHolder.length) {
        error.appendTo($labelHolder);
      } else {
        error.appendTo(element.parent());
      }
    },
    rules: {
      'payOnDelivery': {
        required: {
          depends: function (element) {
            return fields_required
          }
        }
      }
    },
    submitHandler: function (form) {
      event.preventDefault();
      requestPayment();
    }
  });

  {% if paid_order %}
  $('#loadingIcon').show();
  var parameters = "{{redirect_data}}";
  handleSuccess(jQuery.parseJSON(parameters.replace(/&quot;/g,'"')));
  {% endif %}

  {% if showError %}
  var newURL = location.href.split("?")[0];
  window.history.pushState('object', document.title, newURL);
  $('#errorMsgOrder').show();
  {% endif %}
});

function requestPayment() {
  // Disable the submit button to prevent repeated clicks
  $('#buy-btn').prop('disabled', true);
  $('#loadingIcon').show();
  $form.request('onProcessPayment', {
    success: function (data) {
      if(data.X_OCTOBER_REDIRECT){
        window.location = data.X_OCTOBER_REDIRECT;
      }else{
        handleResponse(data);
      }
    }
  });
}

function handleResponse(data) {
  if (data.open_payrexx_modal) {
    $('#payrexx-modal-window').data('href', data.payrexx_gateway_url);
    jQuery("#payrexx-modal-window").trigger('click');
  } else {
    if (data['error']) {
      $('#loadingIcon').hide();
      if (data['is_date_error']) {
        $('#dateErrorMsgOrder').show();
      } else {
        $('#errorMsgOrder').show();
      }
      $form.find('button').prop('disabled', false);
      $('#loadingIcon').hide();
    } else {
      handleSuccess(data);
    }
  }
}

function handleSuccess(data) {
  var tag = {
    "event": "ecommerce_transaction",
    'ecommerce': {
      'purchase': {
        'actionField': data.gtm.actionField,
        'products': data.gtm.products
      }
    }
  };
  dataLayer.push(tag);
  window.location.replace(data.redirect);
}

</script>

-->

<script setup>
import {CartStore} from "@/Store/Cart.js";
import Checkbox from "../Form/Checkbox.vue";
import Input from "../Form/Input.vue";
import DeliveryAddressDropdown from "./DeliveryAddressDropdown.vue";
import Items from "./Items.vue";
import OrderTotal from "./OrderTotal.vue";
import {useForm} from "laravel-precognition-vue-inertia";
import {computed, onMounted, ref} from "vue";
import {Link, usePage} from "@inertiajs/vue3";
import Button from "@/Components/Form/Button.vue";

const isMainRegion = computed(() => usePage().props.isMainRegion);
const packliLink = computed(() => usePage().props.packliLink);

const topSpace = ref(80);
const Vue3StickySidebar = ref(null);

const cartStore = CartStore();
const autoFillAddress = () => {

    const addressId = cartStore.billingAddress.same_as_delivery_address_uuid;

    const address = cartStore.getUniqueAddresses.find(address => address.uuid === addressId);

    if (address) {
        cartStore.setBillingAddress(address);
        cartStore.billingAddress.same_as_delivery_address_uuid = addressId;
    } else {
        cartStore.clearBillingAddress();
        cartStore.billingAddress.same_as_delivery_address_uuid = 0;
    }
    fillAddressFromCartStorage();
};

const onChangeBillingAddress = (e) => {
    if (e.target.checked) {
        cartStore.billingAddress.same_as_delivery_address_uuid = cartStore.getUniqueAddresses[0].uuid;
    } else {
        cartStore.billingAddress.same_as_delivery_address_uuid = 0;
    }
    autoFillAddress();
};

const form = useForm('post', route('checkout.store'), {
    company: cartStore.billingAddress.company,
    first_name: cartStore.billingAddress.first_name,
    last_name: cartStore.billingAddress.last_name,
    street: cartStore.billingAddress.street,
    house_number: cartStore.billingAddress.house_number,
    zip_code: cartStore.billingAddress.zip_code,
    city: cartStore.billingAddress.city,
    phone: cartStore.billingAddress.phone,
    email: cartStore.billingAddress.email,
    items: cartStore.getItemsInCart,
    discount_code: cartStore.discount_code,
    cart_id: cartStore.cart_id,
});

const fillAddressFromCartStorage = () => {
    form.first_name = cartStore.billingAddress.first_name;
    form.last_name = cartStore.billingAddress.last_name;
    form.street = cartStore.billingAddress.street;
    form.house_number = cartStore.billingAddress.house_number;
    form.zip_code = cartStore.billingAddress.zip_code;
    form.city = cartStore.billingAddress.city;
    form.phone = cartStore.billingAddress.phone;
    form.email = cartStore.billingAddress.email;
    form.company = cartStore.billingAddress.company;
    form.discount_code = cartStore.discount_code;
    form.cart_id = cartStore.cart_id;
}

const submit = () => {
    form.items = cartStore.getItemsInCart;
    form.submit({
        preserveScroll: true,
        onSuccess: (data) => {
            cartStore.syncCartWithApi();
        },
        onError: () => {
            cartStore.syncCartWithApi();
        }
    });
};

const onChangeFormAddress = (value) => {
    form.validate(value);
    cartStore.billingAddress[value] = form[value];
}

const formIsValid = computed(() => {
    return cartStore.getItemsInCart.filter(item => !item.shipment_date_error).length > 0
});

onMounted(async () => {
    if (typeof window !== 'undefined') {
        //window.addEventListener('scroll', checkPosition);
        const Vue3StickySidebarComponent = await import('vue3-sticky-sidebar');
        Vue3StickySidebar.value = Vue3StickySidebarComponent.default;
    }
});

</script>

<template>
    <form id="billing-form" @submit.prevent="submit">
        <div id="items">
            <Items :items="cartStore.getItemsInCart"/>
        </div>
        <div id="sticky-element-container" ref="parentRef">
            <div class="row">
                <div class="col-md-8">
                    <div class="well delivery-block order-part">
                        <header>
                            <h2>{{ $t('Rechnungsadresse') }}</h2>
                        </header>
                        <p>{{
                                $t('Bitte gib hier deine Rechnungsadresse an, damit wir die Rechnung bzw. Rechnungskopie per E-Mail senden können. Bitte gib diese Informationen auch bei einem Geschenkgutschein an, damit du das Bestätigungsmail mit den Lieferangaben erhältst.')
                            }}</p>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <div v-if="cartStore.getUniqueAddresses.length === 1" class="checkbox type2">
                                        <Checkbox
                                            :checked="cartStore.billingAddress.same_as_delivery_address_uuid === cartStore.getUniqueAddresses[0].uuid"
                                            data-request="onSameDeliveryAddress"
                                            name="is_same_address"
                                            @change="onChangeBillingAddress">{{
                                                'Gleich wie Lieferadresse'
                                            }}
                                        </Checkbox>
                                    </div>
                                    <div v-if="cartStore.getUniqueAddresses.length > 1" class="row">
                                        <div class="col-sm-12">
                                            <div class="form-group">
                                                <DeliveryAddressDropdown
                                                    @select="autoFillAddress"
                                                />
                                            </div>
                                        </div><!-- / col -->
                                    </div><!-- / row -->
                                </div>
                            </div><!-- / col -->
                        </div><!-- / row -->
                        <div class="row">
                            <div class="col-sm-6">
                                <Input v-model="form.company"
                                       :error="form.errors.company"
                                       :label="$t('Firma')"
                                       autocomplete="new-company"
                                       name="first_name"
                                       @change="onChangeFormAddress('company')"/>
                            </div><!-- / col -->
                        </div><!-- / row -->
                        <div class="row">
                            <div class="col-sm-6">
                                <Input v-model="form.first_name"
                                       :error="form.errors.first_name"
                                       :label="$t('Vorname')"
                                       autocomplete="new-first-name"
                                       name="first_name"
                                       required @change="onChangeFormAddress('first_name')"/>
                            </div><!-- / col -->
                            <div class="col-sm-6">
                                <Input v-model="form.last_name"
                                       :error="form.errors.last_name"
                                       :label="$t('Nachname')"
                                       autocomplete="new-last-name"
                                       name="last_name"
                                       required @change="onChangeFormAddress('last_name')"/>
                            </div><!-- / col -->
                        </div><!-- / row -->
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="row">
                                    <div class="col-xs-7 col-sm-8">
                                        <Input v-model="form.street"
                                               :error="form.errors.street"
                                               :label="$t('Strasse')"
                                               autocomplete="new-street"
                                               name="street"
                                               required @change="onChangeFormAddress('street')"/>
                                    </div><!-- / col -->
                                    <div class="col-xs-5 col-sm-4">
                                        <Input v-model="form.house_number"
                                               :error="form.errors.house_number"
                                               :label="$t('Hausnummer')"
                                               autocomplete="new-house-number"
                                               name="house_number"
                                               required @change="onChangeFormAddress('house_number')"/>
                                    </div><!-- / col -->
                                </div><!-- / row -->
                            </div><!-- / col -->
                            <div class="col-sm-6">
                                <div class="row">
                                    <div class="col-xs-5 col-sm-4">
                                        <Input v-model="form.zip_code"
                                               :error="form.errors.zip_code"
                                               :label="$t('PLZ')"
                                               autocomplete="new-zip-code"
                                               maxlength="4"
                                               minlength="4"
                                               name="zip_code"
                                               required
                                               type="number" @change="onChangeFormAddress('zip_code')"/>
                                    </div><!-- / col -->
                                    <div class="col-xs-7 col-sm-8">
                                        <Input v-model="form.city"
                                               :error="form.errors.city"
                                               :label="$t('Ort')"
                                               autocomplete="new-city"
                                               name="city"
                                               required @change="onChangeFormAddress('city')"/>
                                    </div><!-- / col -->
                                </div><!-- / row -->
                            </div><!-- / col -->
                        </div><!-- / row -->
                        <div class="row">
                            <div class="col-sm-6">
                                <Input v-model="form.phone"
                                       :error="form.errors.phone"
                                       :label="$t('Telefon')"
                                       autocomplete="new-phone"
                                       name="phone"
                                       type="tel"
                                       @change="onChangeFormAddress('phone')"
                                />
                            </div><!-- / col -->
                            <div class="col-sm-6">
                                <Input v-model="form.email"
                                       :error="form.errors.email"
                                       :label="$t('Email')"
                                       autocomplete="new-email"
                                       name="email"
                                       required
                                       type="email"
                                       @change="onChangeFormAddress('email')"/>
                            </div><!-- / col -->
                        </div><!-- / row -->
                        <div class="delivery-note">
                            <p><span
                                class="small">*{{ $t('Pflichtfelder') }}</span></p>
                        </div>
                    </div><!-- / delivery-block -->
                </div><!-- / col -->
                <div class="col-md-4">
                    <Vue3StickySidebar :bottomSpacing="-50" :topSpacing="topSpace"
                                       class="cart-box desktop-only"
                                       containerSelector="#sticky-element-container"
                                       innerWrapperSelector='.sidebar__inner'
                                       stickyClass="sticked_sidebar">
                        <div id="discountCode" class="well order-sum">
                            <OrderTotal/>
                        </div><!-- / order-sum -->
                    </Vue3StickySidebar>
                    <div class="mobile-only cart-box">
                        <div id="discountCode" class="well order-sum">
                            <OrderTotal/>
                        </div>
                    </div>
                </div><!-- / col -->
            </div><!-- / row -->
        </div>
        <div class="row">
            <div class="col-md-8">
                <div class="row btn-holder">
                    <div class="col-sm-6">
                        <Link :href="packliLink" class="btn btn-block btn-default">
                            {{ $t('Noch ein Päckli hinzufügen') }}
                        </Link>
                    </div><!-- / col -->
                    <div class="col-sm-6">
                        <Button :disabled="!formIsValid"
                                :loading="form.processing"
                                class="btn btn-block btn-danger btn-default"
                                type="submit">
                            {{ $t('Weiter zur Bezahlung') }}
                        </Button>
                    </div><!-- / col -->
                </div><!-- / row -->
            </div><!-- / col -->
        </div><!-- / row -->
    </form>
</template>

<style scoped>

</style>
